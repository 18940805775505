import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { companies } from "../../data/data";
import { AnketContext } from "../../context/Context";

const Step1Schema = Yup.object().shape({
  company: Yup.string().required("required"),
  jobType: Yup.string().required("required"),
  job: Yup.string().required("required"),
});

const checkJobType = (jobTypes, jobType) => {
  return jobTypes.find((jt) => jt.type === jobType);
};

const getJobTypes = (company) => {
  let jobTypes = [];
  let inactiveJobTypes = [];
  company.jobs.forEach((job) => {
    job.jobTypes.forEach((jobType) => {
      if (checkJobType(jobTypes, jobType) === undefined && job.enable) {
        jobTypes.push({
          type: jobType,
          enable: job.enable,
        });
      } else if (checkJobType(jobTypes, jobType) === undefined && !job.enable) {
        inactiveJobTypes.push(jobType);
      }
    });
  });
  inactiveJobTypes.forEach(inactiveJobType => {
    if (checkJobType(jobTypes, inactiveJobType) === undefined) {
      jobTypes.push({
        type: inactiveJobType,
        enable: false,
      });
    }
  });
  return jobTypes;
};

const Step1 = ({ company }) => {
  const { setAnket } = useContext(AnketContext);
  const [form, setForm] = useState({
    jobTypes: company ? getJobTypes(company) : null,
    jobs: company ? company.jobs : null,
  });
  const navigate = useNavigate();
  const isCompany = company ? true : false;

  const formik = useFormik({
    initialValues: {
      company: company ? company.slug : "",
      jobType: "",
      job: "",
    },
    validationSchema: Step1Schema,
    onSubmit: (values) => {
      setAnket((prev) => {
        return {
          ...prev,
          step: 2,
          company: values.company,
          jobType: values.jobType,
          job: JSON.parse(values.job),
        };
      });
      navigate("/step/2");
    },
  });

  useEffect(() => {
    formik.setFieldValue("job", "");
    // eslint-disable-next-line
  }, [formik.values.jobType]);

  const handleCompany = (company) => {
    if (formik.values.company !== company.slug) {
      formik.resetForm();
      formik.setFieldValue("company", company.slug);
    }
    setForm({
      jobTypes: getJobTypes(company),
      jobs: company.jobs,
    });
  };

  const renderCompany = (company) => {
    return (
      <div
        className={`company${
          formik.values.company === company.slug ? " active" : ""
        }`}
        key={company.slug}
        onClick={() => handleCompany(company)}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/companies/${company.slug}.png`}
          alt={company.name}
          title={company.name}
        />
      </div>
    );
  };
  
  const workDescription = (jobStr) => {
    const workDescription = JSON.parse(jobStr).workDescription;
    if (workDescription && workDescription.includes('•')) {
      return workDescription.split('•');
    }
    return [workDescription];
  }

  

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit}>
        <input id="company" name="company" hidden />
        <div className="company-selection">
          {isCompany === false && (
            <div className="text">
              Ажилд орохоор сонирхож байгаа компаниа сонгоно уу.
            </div>
          )}
          <div className={`companies${isCompany ? " isCompany" : ""}`}>
            {company
              ? renderCompany(company)
              : companies.map((company) => renderCompany(company))}
          </div>
          {formik.submitCount > 0 && formik.errors.company && (
            <div className="error">Компани сонгоно уу</div>
          )}
        </div>
        <div className="input-container">
          <select
            className="input"
            name="jobType"
            id="jobType"
            value={formik.values.jobType || ""}
            onChange={formik.handleChange}
            disabled={!formik.values.company}
          >
            <option value="">Сонгох</option>
            {form.jobTypes &&
              form.jobTypes.map((jobType) => (
                <React.Fragment key={jobType.type}>
                  {jobType.enable ? (
                    <option key={jobType.type} value={jobType.type}>
                      {`${jobType.type}${
                        jobType.type === "Үндсэн" &&
                        formik.values.company !== "vans" &&
                        formik.values.company !== "converse"
                          ? " - 1 жилээс дээш ажиллах"
                          : ""
                      }${
                        (jobType.type === "Үндсэн" &&
                          formik.values.company === "vans") ||
                        (jobType.type === "Үндсэн" &&
                          formik.values.company === "converse")
                          ? " - Бүтэн цагийн ажилтан (Оюутан биш)"
                          : ""
                      }`}
                      {jobType.enable}
                    </option>
                  ) : (
                    <option
                      key={jobType.type}
                      value={jobType.type}
                      disabled
                    >{`${jobType.type}${
                      jobType.type === "Үндсэн" &&
                      formik.values.company !== "vans" &&
                      formik.values.company !== "converse"
                        ? " - 1 жилээс дээш ажиллах"
                        : ""
                    }${
                      (jobType.type === "Үндсэн" &&
                        formik.values.company === "vans") ||
                      (jobType.type === "Үндсэн" &&
                        formik.values.company === "converse")
                        ? " - Бүтэн цагийн ажилтан (Оюутан биш)"
                        : ""
                    }`}</option>
                  )}
                </React.Fragment>
              ))}
          </select>
          <div className="label">
            <div className="text">Ажиллах цагийн төрөл</div>
          </div>
          {formik.submitCount > 0 &&
            formik.errors.jobType &&
            !formik.values.jobType && (
              <div className="error">Ажиллах цагийн төрөлөө сонгоно уу</div>
            )}
        </div>
        <div className="input-container">
          <select
            className="input"
            name="job"
            id="job"
            value={formik.values.job || ""}
            onChange={formik.handleChange}
            disabled={!formik.values.jobType}
          >
            <option value="">Сонгох</option>
            {form.jobs &&
              form.jobs
                .filter((job) => job.jobTypes.includes(formik.values.jobType) && job.enable)
                .map((job) => (
                  <option
                    key={job.position}
                    value={JSON.stringify(job)}
                  >{`${job.position}`}</option>
                ))}
          </select>
          <div className="label">
            <div className="text">Сонирхож буй ажлын байр</div>
          </div>
          {formik.values.job && (
            <div className="description">
              {JSON.parse(formik.values.job).salary !== "" && (
                <div className="item salary">{`${
                  JSON.parse(formik.values.job).salary
                }`}</div>
              )}
              <div className="item working-hours">{`${
                JSON.parse(formik.values.job).workHours
              }`}</div>
              <div className="item work-days">{`${
                JSON.parse(formik.values.job).workDays
              }хоног`}</div>
              {JSON.parse(formik.values.job).bonus &&
                JSON.parse(formik.values.job).bonus.length > 0 && (
                  <div className="item bonus">
                    <div>
                      {JSON.parse(formik.values.job).bonus.map((job) => (
                        <React.Fragment key={job}>
                          {job}
                          <br />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                )}
              <div className="item work-description">
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {workDescription(formik.values.job).map(wd => <div key={wd}>{wd}</div>)}
                </div>
              </div>
            </div>
          )}
          {formik.submitCount > 0 && formik.errors.job && (
            <div className="error">Ажлын байр сонгоно уу</div>
          )}
        </div>
        <div className="btn-container">
          <button type="submit" className="btn-primary">
            Үргэлжлүүлэх
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step1;
